import { PROPERTIES_TAGS_CODES } from '~/constants'
import type { LibraryItem } from '~/types'
import type { Property } from '~/types/properties'

export default (tagsList?: LibraryItem[], tagIds?: Property['tag_ids']) => {
  if (!tagIds?.length || !tagsList?.length) return []

  const TAGS_NAME = {
    [PROPERTIES_TAGS_CODES.PROPERTY_EXCLUSIVE]: 'Exclusive',
  }

  const getTagIcon = (id: LibraryItem['id']) => {
    const code = tagsList?.find((tag) => tag.id === id)?.code

    const ICONS_MAP = {
      [PROPERTIES_TAGS_CODES.PROPERTY_EXCLUSIVE]: 'diamond',
    }

    return ICONS_MAP[code as PROPERTIES_TAGS_CODES]
  }

  return tagIds.map((tagId) => {
    const tag = tagsList.find((tag) => tag.id === tagId)

    if (!tag) {
      throw new Error(`Tag with id ${tagId} not found`)
    }

    return { ...tag, icon: getTagIcon(tag.id), name: TAGS_NAME[tag.code as PROPERTIES_TAGS_CODES] }
  })
}
